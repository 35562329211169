
import { Options, Vue } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { namespace } from "s-vuex-class";

const GocardlessModule = namespace("gocardless");

@Options({
  components: { DefaultLayout },
})
export default class SetUpMandateSuccess extends Vue {
  private successMessage = "Mandate imported successfully";

  @GocardlessModule.Action("setGocardless")
  public setGocardless!: (payload: boolean) => void;

  public created() {
    this.setGocardless(true);
    this.$router.push({ name: "Home" });
  }
}
